import {
  TimeStamp,
  PointRecord,
  User,
  Category,
  OptionItems,
  ColorOptionItems,
  Admin,
} from '.';

export interface Reward extends TimeStamp {
  rewardId: number;
  categoryId: number;
  image: string;
  title: string;
  quota: number;
  pointPerUnit: number;
  Category: Category;
  description: string;
}
export interface RedeemRecord extends TimeStamp {
  redeemRecordId: number;
  userId: number;
  status: string;
  redeemedByAdminId: number;
  RedeemedBy: Admin;
  cancelleddByAdminId: number;
  CancelledBy: Admin;
  remarks: string;
  totalPoint: number;
  User: User;
  RedeemItems: RedeemItem[];
  PointRecord: PointRecord;
}

export const RedeemRecordStatusOption: OptionItems[] = [
  { label: '待確認', value: 'Pending' },
  { label: '已換領', value: 'Redeemed' },
  { label: '會員取消', value: 'UserCancel' },
  { label: '管理員取消', value: 'AdminCancel' },
];

export const RedeemRecordStatusMap = new Map<string, ColorOptionItems>([
  ['Pending', { label: '待確認', value: 'Pending', colorType: 'secondary' }],
  ['Redeemed', { label: '已換領', value: 'Redeemed', colorType: 'success' }],
  [
    'UserCancel',
    { label: '會員取消', value: 'UserCancel', colorType: 'warning' },
  ],
  [
    'AdminCancel',
    { label: '管理員取消', value: 'AdminCancel', colorType: 'danger' },
  ],
]);
export interface RedeemItem extends TimeStamp {
  redeemItemId: number;
  redeemRecordId: number;
  pointPerUnit: number;
  rewardId: number;
  amount: number;
  Reward: Reward;
}

export interface RedeemItemPayload {
  redeemItemId?: number;
  rewardId: string;
  amount: number;
}

export interface RewardPolicy extends TimeStamp {
  rewardId: number;
  content: string;
}
