import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (value: any) => void;
  onInputChange?: (inputValue: any, actionMeta: any) => void;
  isClearable?: boolean;
  disabled?: boolean;
  allowCreateWhileLoading?: boolean;
  createOptionPosition?: 'first' | 'last';
  formatCreateLabel?: (inputValue: string) => string;
  isValidNewOption?: (inputValue: string) => boolean;
  getNewOptionData?: (inputValue: string) => OptionItems;
  onCreateOption?: (inputValue: string) => void;
  isInModal?: boolean;
}

const CreateableSelectField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField<any>(props);
  const {
    label,
    placeholder,
    options,
    onChange,
    isClearable,
    disabled,
    allowCreateWhileLoading,
    createOptionPosition,
    formatCreateLabel,
    isValidNewOption,
    getNewOptionData,
    onCreateOption,
    isInModal = false,
  } = props;
  const handleChange = (
    newValue: OnChangeValue<OptionItems, false>,
    actionMeta: ActionMeta<OptionItems>,
  ) => {
    if (newValue) {
      helpers.setValue(newValue.value);
    } else {
      helpers.setValue('');
    }
  };
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    }
    return null;
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <CreatableSelect
        {...field}
        classNamePrefix="select2-selection"
        defaultValue={rendervalue()}
        value={rendervalue()}
        // isMulti={false}
        placeholder={placeholder}
        options={options}
        formatCreateLabel={
          formatCreateLabel
            ? formatCreateLabel
            : inputValue => ` 新增 "${inputValue}"`
        }
        onChange={onChange ? onChange : handleChange}
        allowCreateWhileLoading={allowCreateWhileLoading}
        createOptionPosition={createOptionPosition}
        isValidNewOption={isValidNewOption}
        getNewOptionData={getNewOptionData}
        onCreateOption={onCreateOption}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPortalTarget={!isInModal ? document.body : undefined}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default CreateableSelectField;
