import { Filter, ListResult } from 'app/models';
import { scheduledNotification } from 'app/models/';
import Axios from '.';

/**
 * Get Notification list (https://flcp-dev.appicidea.com/doc/#/Notification/notificationControllerListNotifications)
 */

export interface GetNotificationListParams extends Filter {}

export const getNotificationList = async (
  params: GetNotificationListParams,
) => {
  const { data } = await Axios.get<ListResult<scheduledNotification>>(
    '/notifications/schedule',
    {
      params,
    },
  );
  return data;
};

/**
 * Create new Notification  (https://flcp-dev.appicidea.com/doc/#/Notification/notificationControllerCreateNotification)
 */

export interface CreateNewNotificationParams {
  title: string;
  scheduledTime: string;
  volunteerId: number | null;
  newsId: number | null;
  attendRecordId?: number | null;
  redeemRecordId?: number | null;
  pointRecordId?: number | null;
  rewardId?: number | null;
}

export const createNewNotification = async (
  params: CreateNewNotificationParams,
) => {
  const { data } = await Axios.post<scheduledNotification>(
    `/notifications/schedule`,
    params,
  );
  return data;
};

/**
 * Delete one Notification(https://flcp-dev.appicidea.com/doc/#/Notification/notificationControllerDeleteNotificationById)
 */

export const deleteOneNotification = async (notificationId: number) => {
  const { data } = await Axios.delete<string>(
    `/notifications/schedule/${notificationId}`,
  );
  return data;
};
