import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import Select from 'react-select';
import { useLocation, useHistory } from 'react-router-dom';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface Props {
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (e) => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
  isInModal?: boolean;
}

const SelectFilter = ({
  label,
  placeholder,
  options,
  onChange,
  isClearable,
  disabled,
  menuPlacement,
  name,
  isInModal = false,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState<OptionItems | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    // eslint-disable-next-line
    const index = _.findIndex(options, option => option.value == key);
    if (index > -1) {
      setValue(options[index]);
    } else {
      setValue(null);
    }
  }, [location.search, name, options]);
  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <Select
        defaultValue={value}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        value={value}
        onChange={e => {
          const params = new URLSearchParams(location.search);
          if (e?.value) {
            params.set(name, e.value);
          } else {
            params.delete(name);
          }
          params.set('page', '1');
          history.push({ search: params.toString() });
          if (onChange) {
            onChange(e);
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
        menuPortalTarget={!isInModal ? document.body : undefined}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
};

export default SelectFilter;
