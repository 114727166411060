import {
  ColorOptionItems,
  News,
  OptionItems,
  Notification,
  TimeStamp,
  User,
  Admin,
} from '.';

export type ApprovalStatus = '等待批准' | '已批准';
export interface Volunteer extends TimeStamp {
  volunteerId: number;
  title: string;
  images: string[] | null;
  date: string;
  startTime: string;
  endTime: string;
  virtualQuota: number;
  physicalQuota: number;
  hasVirtualSession: boolean;
  hasPhysicalSession: boolean;
  onlineSessionLink: string;
  applicationDeadline: string | null;
  isStopReceiving: boolean;
  applicationStatus: string;
  approvalStatus: ApprovalStatus;
  availableTypes: string[];
  waitingListQuota: number;
  quotaRemarks: string;
  attendedPoint: number;
  fee: number;
  content: string;
  location: string;
  lng: number;
  lat: number;
  Notifications: Notification[];
  Newses: News[];
  AttendRecords: AttendRecord[];
}

export interface AttendRecord extends TimeStamp {
  attendRecordId: number;
  userId: number;
  volunteerId: number;
  contactName: string;
  contactPhone: string;
  preferredType: string;
  assignedType: string;
  status: string;
  attendPoint: number;
  attendRecordedByAdminId: number;
  AttendRecordedBy: Partial<Admin>;
  attendTime: string;
  leaveRecordedByAdminId: number;
  LeaveRecordedBy: Partial<Admin>;
  leaveTime: string;
  approvedByAdminId: number | null;
  ApprovedBy: Partial<Admin> | null;
  canceledByAdminId: number | null;
  CancelledBy: Partial<Admin> | null;
  User: User;
  Volunteer: Volunteer;
}

export const AttendRecordStatusOption: OptionItems[] = [
  {
    value: '確認名額中',
    label: '確認名額中',
  },
  {
    value: '名額已確認',
    label: '名額已確認',
  },
  {
    value: '已出席',
    label: '已出席',
  },
  {
    value: '已離開',
    label: '已離開',
  },
  {
    value: '已取消',
    label: '已取消',
  },
];

export const AttendRecordStatusMap = new Map<string, ColorOptionItems>([
  [
    '確認名額中',
    { label: '確認名額中', value: '確認名額中', colorType: 'secondary' },
  ],
  [
    '名額已確認',
    { label: '名額已確認', value: '名額已確認', colorType: 'info' },
  ],
  ['已出席', { label: '已出席', value: '已出席', colorType: 'success' }],
  ['已離開', { label: '已離開', value: '已離開', colorType: 'warning' }],
  ['已取消', { label: '已取消', value: '已取消', colorType: 'danger' }],
]);

export const VolunteerEventType: OptionItems[] = [
  {
    value: '實體活動',
    label: '實體活動',
  },
  {
    value: '網上活動',
    label: '網上活動',
  },
];
