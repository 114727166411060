import React, { useState } from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import { Label, Card, Row, Col, Button } from 'reactstrap';
import { LocalFile } from 'app/models';
import { Link } from 'react-router-dom';
import { getImageURL } from 'app/helpers/CommonHelper';
import { formatBytes } from 'app/helpers/FormHelper';
import { IMAGE_SIZE } from 'app/config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './form.scss';

interface OtherProps {
  label?: string;
  multiple?: boolean;
}

const ImageField = ({
  label,
  multiple,
  ...props
}: OtherProps & FieldHookConfig<LocalFile[] | null>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  const [previewVisible, setPreviewVisible] = useState<string | null>(null);
  const onDelete = (original, index) => {
    const temp = [...original];
    temp.splice(index, 1);
    if (temp.length > 0) {
      helper.setValue(temp);
    } else {
      helper.setValue(null);
    }
  };

  const onDrop = files => {
    let images: LocalFile[] = [];
    if (field.value) {
      images = [...field.value];
    }
    files.forEach(file => {
      if (file.size > IMAGE_SIZE) {
        toast.error('通告圖片不可大於10MB');
      }
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png'
      ) {
        file['preview'] = URL.createObjectURL(file);
        file['formattedSize'] = formatBytes(file.size);
        if (multiple) {
          images.push(file);
        } else {
          images = [file];
        }
        helper.setValue(images);
      } else {
        toast.error('圖片只接受.jpg / .png檔案');
      }
    });
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{label}</Label> : null}
      <Dropzone
        multiple={multiple ? true : false}
        onDrop={acceptedFiles => {
          onDrop(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>點擊或拉動圖片到此處</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {field.value
          ? field.value.map((file, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + '-file'}
                >
                  <div className="p-2">
                    <Row className="align-items-start">
                      <Col className="col-auto">
                        <img
                          onClick={() =>
                            setPreviewVisible(
                              file.size
                                ? file.preview
                                : getImageURL(file.preview, 'original'),
                            )
                          }
                          data-dz-thumbnail=""
                          height="80px"
                          className="rounded bg-light preview-image"
                          alt={file.name || ''}
                          src={
                            file.size ? file.preview : getImageURL(file.preview)
                          }
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {file.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{file.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          color="danger"
                          onClick={() => onDelete(field.value, i)}
                        >
                          <i className="bx bx-trash-alt" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })
          : null}
      </div>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      {previewVisible ? (
        <Lightbox
          mainSrc={previewVisible}
          enableZoom={false}
          onCloseRequest={() => {
            setPreviewVisible(null);
          }}
        />
      ) : null}
    </div>
  );
};

export default ImageField;
