import Axios from '.';
import { ListResult, Volunteer, Filter, AttendRecord } from '../models';

/**
 *  Get Volunteer work List (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerListVolunteers)
 */
export interface GetVolunteerListParams extends Filter {
  eventDateStart?: string;
  eventDateEnd?: string;
  name?: string;
  applicationStatus?: string;
  approvalStatus?: string;
}

export const getVolunteerList = async (params: GetVolunteerListParams) => {
  const { data } = await Axios.get<ListResult<Volunteer>>('/volunteers', {
    params,
  });
  return data;
};

/**
 *  Create a Volunteer work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerCreateNews)
 */
export interface CreateNewVolunteerParams {
  title: string;
  images: string[] | null;
  date: string | null;
  startTime: string;
  endTime: string;
  virtualQuota: number;
  physicalQuota: number;
  hasVirtualSession: boolean;
  hasPhysicalSession: boolean;
  onlineSessionLink: string;
  attendedPoint: number;
  applicationDeadline: string | null;
  isStopReceiving: boolean;
  content: string;
  location: string;
  lng: number;
  lat: number;
}

export const createNewVolunteer = async (params: CreateNewVolunteerParams) => {
  const { data } = await Axios.post<Volunteer>('/volunteers', params);
  return data;
};

/**
 *  Get One Volunteer work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerGetNewsById)
 */

export const getOneVolunteer = async (volunteerId: string) => {
  const { data } = await Axios.get<Volunteer>(`/volunteers/${volunteerId}`);
  return data;
};

/**
 *  Update One Volunteer work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerUpdateNewsById)
 */

export const updateOneVolunteer = async (
  volunteerId: string,
  params: Partial<CreateNewVolunteerParams>,
) => {
  const { data } = await Axios.patch<null>(
    `/volunteers/${volunteerId}`,
    params,
  );
  return data;
};

/**
 *  Delete one Volunteer work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerDeleteNewsById)
 */
export const deleteOneVolunteer = async (volunteerId: number) => {
  const { data } = await Axios.delete<string>(`/volunteers/${volunteerId}`);
  return data;
};

/**
 *  Get Attend Requests for one Volunteer work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerGetAttendRecord)
 */

export interface GetAllAttendRecordsByVolunteerIdParams extends Filter {
  status?: string;
  q?: string;
  preferredType?: string;
  assignedType?: string;
}

export const getAllAttendRecordsByVolunteerId = async (
  volunteerId: string | number,
  params: GetAllAttendRecordsByVolunteerIdParams,
) => {
  const { data } = await Axios.get<ListResult<AttendRecord>>(
    `/volunteers/${volunteerId}/attends`,
    {
      params,
    },
  );
  return data;
};

/**
 *  Get Attend Requests for one Volunteer work exclude rejected And pending Status (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerGetAllAttendRecord)
 */

export interface GetAttendRecordsWithoutPendingAndRejectByVolunteerIdParams
  extends Filter {}

export const getAttendRecordsWithoutPendingAndRejectByVolunteerId = async (
  volunteerId: string | number,
  params: GetAttendRecordsWithoutPendingAndRejectByVolunteerIdParams,
) => {
  const { data } = await Axios.get<ListResult<AttendRecord>>(
    `/volunteers/${volunteerId}/attends`,
    {
      params,
    },
  );
  return data;
};

/**
 *  Send notification of one volunteer by volunteerId (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerSendNotification)
 */
export interface CreateNewVolunteerNotificationParams {
  title: string;
}

export const createNewVolunteerNotification = async (
  volunteerId: number,
  params: CreateNewVolunteerNotificationParams,
) => {
  const { data } = await Axios.post<Volunteer>(
    `/volunteers/${volunteerId}/notification`,
    params,
  );
  return data;
};

/**
 *  Approve one volunteer, By Admin (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerApproveNewsById)
 */
export const approveOneVolunteer = async (volunteerId: number) => {
  const { data } = await Axios.post<any>(`/volunteers/${volunteerId}/approve/`);
  return data;
};

/**
 *  Get All Attend Requests  (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerListAttendRecords)
 */

export interface GetAttendRecordsListParams extends Filter {
  userId?: string;
  volunteerId?: string;
  status?: string;
  preferredType?: string;
  assignedType?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export const getAttendRecordsList = async (
  params: GetAttendRecordsListParams,
) => {
  const { data } = await Axios.get<ListResult<AttendRecord>>(
    `/volunteers/attendRecords`,
    {
      params,
    },
  );
  return data;
};

/**
 *  Create a AttendRecord work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerAddOneAttendRecordedByAdmin)
 */
export interface CreateNewAttendRecordParams {
  userId: number;
  volunteerId: number;
  contactName: string;
  contactPhone: string;
  preferredType: string;
  assignedType: string;
  status: string;
}
export const createNewAttendRecord = async (
  params: CreateNewAttendRecordParams,
) => {
  const { data } = await Axios.post<AttendRecord>('/volunteers/attend', params);
  return data;
};

/**
 *  Get One AttendRecord work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerGetOneAttendRecord)
 */

export const getOneAttendRecord = async (attendRecordId: string) => {
  const { data } = await Axios.get<AttendRecord>(
    `/volunteers/attend/${attendRecordId}`,
  );
  return data;
};

/**
 *  Update One AttendRecord work (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerUpdateOneAttendRecord)
 */
export interface UpdateOneAttendRecordParams {
  contactName: string;
  contactPhone: string;
  assignedType: string;
}
export const updateOneAttendRecord = async (
  attendRecordId: string,
  params: UpdateOneAttendRecordParams,
) => {
  const { data } = await Axios.patch<null>(
    `/volunteers/attend/${attendRecordId}`,
    params,
  );
  return data;
};

/**
 *  Delete one AttendRecord work (https://flcp-dev.appicidea.com/doc/#/AttendRecord/attendRecordsControllerDeleteNewsById)
 */
export const deleteOneAttendRecord = async (attendRecordId: number) => {
  const { data } = await Axios.delete<string>(
    `/volunteers/attend/${attendRecordId}`,
  );
  return data;
};

/**
 *  Approve One Attend record (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerApproveAttendRecord)
 */
export interface ApproveOneAttendRecordParams {
  assignedType: string;
}

export const approveOneAttendRecord = async (
  attendRecordId: string,
  params: ApproveOneAttendRecordParams,
) => {
  const { data } = await Axios.post<any>(
    `/volunteers/attend/${attendRecordId}/approve`,
    params,
  );
  return data;
};

/**
 *  Cancel One Attend record (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerCancelAttendRecord)
 */
export const cancelOneAttendRecord = async (attendRecordId: string) => {
  const { data } = await Axios.post<any>(
    `/volunteers/attend/${attendRecordId}/cancel`,
  );
  return data;
};

/**
 *  Set Enter Status of One Attend record using VolunteerId, userId, By Admin (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerEnterAttendRecord)
 */
export const setEnterStatusForOneAttendRecord = async (
  volunteerId: string,
  userId: string,
) => {
  const { data } = await Axios.post<any>(
    `/volunteers/${volunteerId}/attend/${userId}/enter`,
  );
  return data;
};

/**
 *  Set Leave Status of One Attend record, By Admin (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerLeaveAttendRecord)
 */
export const setLeaveStatusForOneAttendRecord = async (
  volunteerId: string,
  userId: string,
) => {
  const { data } = await Axios.post<any>(
    `/volunteers/${volunteerId}/attend/${userId}/leave`,
  );
  return data;
};

/**
 * Batch Approve record (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerBatchApproveAttendRecords)
 */

export const batchApproveAttendRecord = async (params: number[]) => {
  const { data } = await Axios.post<any>(
    `/volunteers/attendRecords/batchApprove`,
    params,
  );
  return data;
};

/**
 * Batch Reject record (https://flcp-dev.appicidea.com/doc/#/Volunteer/volunteersControllerBatchRejectAttendRecords)
 */
export const batchRejectAttendRecord = async (params: number[]) => {
  const { data } = await Axios.post<any>(
    `/volunteers/attendRecords/batchReject`,
    params,
  );
  return data;
};
