import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface Props {
  label?: string;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (value: string | number) => void;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onInputChange: (e) => void;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
  isClearable?: boolean;
  isInModal?: boolean;
}

const AutoCompleteSelect = ({
  label,
  placeholder,
  options,
  onChange,
  onMenuScrollToBottom,
  onMenuClose,
  onInputChange,
  disabled,
  menuPlacement,
  name,
  isClearable,
  isInModal = false,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState<OptionItems | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    const keyLabel = query.get(`${name}Label`);
    if (key && keyLabel) {
      setValue({
        value: key,
        label: keyLabel,
      });
    } else {
      setValue(null);
    }
  }, [location.search, name, options]);

  const searchOnChange = text => {
    setInputValue(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(_.debounce(onInputChange, 500), []);

  useEffect(() => {
    debounceSearch(inputValue);
  }, [inputValue, debounceSearch]);

  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <Select
        defaultValue={value}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        value={value}
        onChange={e => {
          const params = new URLSearchParams(location.search);
          if (onChange) {
            if (e?.value) {
              onChange(e.value);
              Array.from(params.entries())
                .filter(([key]) => key !== name && key !== `${name}Label`)
                .forEach(([key, value]) => params.set(key, value));
              params.set(name, e.value);
              params.set(`${name}Label`, e.label);
            } else {
              onChange('');
              params.delete(`${name}Label`);
              params.delete(name);
            }
          } else {
            if (e && e.value) {
              params.set(name, e.value);
              params.set(`${name}Label`, e.label);
            } else {
              params.delete(name);
              params.delete(`${name}Label`);
            }
            params.set('page', '1');
          }
          history.push({ search: params.toString() });
        }}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onMenuClose={onMenuClose}
        captureMenuScroll={true}
        // onInputChange={searchOnChange}
        onInputChange={
          searchOnChange
            ? e => {
                if (!(e === '' && options.length >= 1)) {
                  searchOnChange(e);
                }
              }
            : undefined
        }
        // inputValue={inputValue}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
        isClearable={isClearable}
        menuPortalTarget={!isInModal ? document.body : undefined}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
};

export default AutoCompleteSelect;
