import { PAGE_SIZE } from 'app/config';
import React from 'react';
import { Table } from 'reactstrap';
import './table.scss';

interface Props {
  columns: any[];
  data: any[];
  keyField: string;
  noWrap?: boolean;
  onCheck: (item: any) => void;
  isCheckBoxShow?: (item: any) => boolean;
  selected: Record<string | number, any>;
  checkAll: () => void;
}

function SelectableTable({
  columns,
  data,
  keyField,
  noWrap,
  onCheck,
  isCheckBoxShow,
  selected,
  checkAll,
}: Props) {
  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          <th onClick={() => checkAll()}>
            {isCheckBoxShow && data.filter(isCheckBoxShow).length !== 0 ? (
              <>
                {((Object.keys(selected).length === PAGE_SIZE ||
                  Object.keys(selected).length === data.length) &&
                  Object.keys(selected).length > 0) ||
                (isCheckBoxShow &&
                  Object.keys(selected).length ===
                    data.filter(isCheckBoxShow).length) ? (
                  <i className="bx bx-check-square check-icon active" />
                ) : (
                  <i className="bx bx-square check-icon" />
                )}
              </>
            ) : null}
          </th>
          {columns.map(column => (
            <th key={column.text}>{column.text}</th>
          ))}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={item[keyField]}>
                <td
                  style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                  onClick={() => {
                    if (!isCheckBoxShow || isCheckBoxShow(item)) onCheck(item);
                  }}
                >
                  {!isCheckBoxShow || isCheckBoxShow(item) ? (
                    <>
                      {selected[item[keyField]] ? (
                        <i className="bx bx-check-square check-icon active" />
                      ) : (
                        <i className="bx bx-square check-icon" />
                      )}
                    </>
                  ) : null}
                </td>
                {columns.map((column, subIndex) => {
                  if (column.formatter) {
                    return (
                      <td
                        key={`${item[keyField]}_${index}_${subIndex}`}
                        style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                      >
                        {column.formatter(item)}
                      </td>
                    );
                  }
                  return (
                    <td key={`${item[keyField]}_${index}_${subIndex}`}>
                      {item[column.dataField]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td
              colSpan={columns.length + 1}
              className="text-center border-0 text-size-16 fw-bold"
            >
              沒有資料
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );
}

export default SelectableTable;
