import { OptionItems } from '.';
import { TimeStamp } from './Common';

export interface AdminLoginPayload {
  loginName: string;
  password: string;
}

export interface Admin extends TimeStamp {
  adminId: number;
  role: AdminRoleType;
  loginName: string;
  email: string;
  displayName: string;
  avatar: string | null;
  notificationToken: string | null;
  hash: string | null;
}

export type AdminRoleType = '超級管理員' | '職員';
export enum AdminRoleEnum {
  SuperAdmin = '超級管理員',
  Admin = '職員',
}

export interface AdminRoleInfo {
  label: string;
  value: string;
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
}

export const ADMIN_ROLES = new Map<AdminRoleType, AdminRoleInfo>([
  ['超級管理員', { label: '超級管理員', value: '超級管理員', color: 'danger' }],
  ['職員', { label: '職員', value: '職員', color: 'success' }],
]);

export const ADMIN_ROLES_OPTIONS: OptionItems[] = [
  {
    label: '超級管理員',
    value: '超級管理員',
  },
  {
    label: '職員',
    value: '職員',
  },
];
