import { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import { Form, Formik, FormikHelpers } from 'formik';

import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { APP_NAME, PAGE_SIZE } from 'app/config';

import {
  AutoCompleteSelectField,
  DateField,
  InputField,
  SelectField,
} from 'app/components/Form';
import * as Yup from 'yup';

import {
  createNewNotification,
  CreateNewNotificationParams,
} from 'app/services/NotificationService';

import { News, OptionItems, Reward, Volunteer } from 'app/models';
import { getVolunteerList } from 'app/services/VolunteerService';
import { getNewsList } from 'app/services/NewsService';
import moment from 'moment';
import _ from 'lodash';
import { getRewardItemList } from 'app/services/RewardService';

const Schema = Yup.object().shape({
  title: Yup.string()
    .required('必填項目')
    .min(2, '最少要2個字元')
    .nullable()
    .max(250, '不可多於100個字元'),
  scheduledDate: Yup.date().required('必填項目').nullable(),
  scheduledTime: Yup.string().required('必填項目'),
  notificationType: Yup.string().required('必填項目').nullable(),
  volunteerId: Yup.number()
    .nullable()
    .when('notificationType', (notificationType: any, field: any) => {
      return notificationType === 'volunteer'
        ? field.required('必填項目')
        : field;
    }),
  newsId: Yup.number()
    .nullable()
    .when('notificationType', (notificationType: any, field: any) => {
      return notificationType === 'news' ? field.required('必填項目') : field;
    }),
  rewardId: Yup.number()
    .nullable()
    .when('notificationType', (notificationType: any, field: any) => {
      return notificationType === 'rewards'
        ? field.required('必填項目')
        : field;
    }),
});

type FormItem = {
  title: string;
  scheduledDate: Date | Date[] | null;
  scheduledTime: string;
  volunteerId: number | null;
  newsId: number | null;
  attendRecordId?: number | null;
  redeemRecordId?: number | null;
  pointRecordId?: number | null;
  notificationType: string;
  rewardId: number | null;
};

const FORM_ITEM: FormItem = {
  notificationType: '',
  title: '',
  scheduledDate: null,
  scheduledTime: '',
  volunteerId: null,
  newsId: null,
  rewardId: null,
};

export const NotificationFormPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const [form] = useState<FormItem>(FORM_ITEM);
  const [loading, setLoading] = useState<boolean>(false);
  const [volunteerList, setVolunteerList] = useState<OptionItems[]>([]);
  const [volunteerText, setVolunteerText] = useState<string>('');
  const [newsList, setNewsList] = useState<OptionItems[]>([]);
  const [newsText, setNewsText] = useState<string>('');
  const [rewardList, setRewardList] = useState<OptionItems[]>([]);
  const [rewardText, setRewardText] = useState<string>('');

  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    const {
      title,
      scheduledDate,
      scheduledTime,
      volunteerId,
      newsId,
      rewardId,
      notificationType,
    } = values;
    const params: CreateNewNotificationParams = {
      title,
      scheduledTime:
        scheduledDate?.[0] && scheduledTime
          ? moment(scheduledDate[0]).add(scheduledTime, 'hours').toISOString()
          : '',
      volunteerId:
        notificationType === 'volunteer' && volunteerId ? volunteerId : null,
      newsId: notificationType === 'news' && newsId ? newsId : null,
      rewardId: notificationType === 'rewards' && rewardId ? rewardId : null,
    };

    try {
      await createNewNotification(params);
      setLoading(false);
      toast.success('新增預設通知成功。');
      history.push(`/notifications`);
      dispatch(changePreloader(false));
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.data) {
        const { message } = err.response.data;
        toast.error(
          `新增或編輯預設通知失敗,  \n 原因: ${message} \n
          。`,
        );
      } else {
        toast.error('新增或編輯預設通知失敗，請重試。');
      }
    }
  };

  const getVolunteers = useCallback(async () => {
    try {
      const volunteerRes = await getVolunteerList({
        limit: PAGE_SIZE,
        offset: 0,
        name: volunteerText,
        approvalStatus: '已批准',
      });
      setVolunteerList(
        volunteerRes.rows.map((volunteer: Volunteer) => ({
          label: `#${volunteer.volunteerId} | ${volunteer.title}`,
          value: volunteer.volunteerId.toString(),
        })),
      );
    } catch (err) {
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
  }, [volunteerText]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers]);

  const volunteerScrollToBottom = async () => {
    let offset = 0;
    if (volunteerList.length === 0) {
      offset = 0;
    } else {
      offset = Math.ceil(volunteerList.length / PAGE_SIZE) * PAGE_SIZE;
    }
    try {
      const volunteerRes = await getVolunteerList({
        limit: PAGE_SIZE,
        offset: offset,
        name: volunteerText,
        approvalStatus: '已批准',
      });
      setVolunteerList(prev => {
        return _.uniqBy(
          [
            ...prev.concat(
              volunteerRes.rows.map(volunteer => {
                return {
                  label: `#${volunteer.volunteerId} | ${volunteer.title}`,
                  value: volunteer.volunteerId.toString(),
                };
              }),
            ),
          ],
          'value',
        );
      });
    } catch (err) {
      console.error(err);
      toast.error(`加載列表失敗，請重試。`);
    }
  };

  const getNews = useCallback(async () => {
    try {
      const newsRes = await getNewsList({
        limit: PAGE_SIZE,
        offset: 0,
        title: newsText,
        approvalStatus: '已批准',
      });
      setNewsList(
        newsRes.rows.map((news: News) => ({
          label: `#${news.newsId} | ${news.title}`,
          value: news.newsId.toString(),
        })),
      );
    } catch (err) {
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
  }, [newsText]);

  useEffect(() => {
    getNews();
  }, [getNews]);

  const newsScrollToBottom = async () => {
    let offset = 0;
    if (newsList.length === 0) {
      offset = 0;
    } else {
      offset = Math.ceil(newsList.length / PAGE_SIZE) * PAGE_SIZE;
    }
    try {
      const newsRes = await getNewsList({
        limit: PAGE_SIZE,
        offset: offset,
        title: newsText,
        approvalStatus: '已批准',
      });
      setNewsList(prev => {
        return _.uniqBy(
          [
            ...prev.concat(
              newsRes.rows.map(news => {
                return {
                  label: `#${news.newsId} | ${news.title}`,
                  value: news.newsId.toString(),
                };
              }),
            ),
          ],
          'value',
        );
      });
    } catch (err) {
      console.error(err);
      toast.error(`加載列表失敗，請重試。`);
    }
  };

  const rewardScrollToBottom = async () => {
    let offset = 0;
    if (newsList.length === 0) {
      offset = 0;
    } else {
      offset = Math.ceil(newsList.length / PAGE_SIZE) * PAGE_SIZE;
    }
    try {
      const newsRes = await getRewardItemList({
        limit: PAGE_SIZE,
        offset: offset,
        q: rewardText,
      });
      setRewardList(prev => {
        return _.uniqBy(
          [
            ...prev.concat(
              newsRes.rows.map((reward: Reward) => {
                return {
                  label: `#${reward.rewardId} | ${reward.title}`,
                  value: reward.rewardId.toString(),
                };
              }),
            ),
          ],
          'value',
        );
      });
    } catch (err) {
      console.error(err);
      toast.error(`加載列表失敗，請重試。`);
    }
  };

  const getRewards = useCallback(async () => {
    try {
      const rewardRes = await getRewardItemList({
        limit: PAGE_SIZE,
        offset: 0,
        q: rewardText,
      });
      setRewardList(
        rewardRes.rows.map((reward: Reward) => ({
          label: `#${reward.rewardId} | ${reward.title}`,
          value: reward.rewardId.toString(),
        })),
      );
    } catch (err) {
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
  }, [rewardText]);

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  const TITLE = `新增預設通知`;
  const BreadcrumbsOptions = [
    { title: '預設通知', path: '/notifications' },
    { title: '預設通知列表', path: '/notifications' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="預設通知" breadcrumbItems={BreadcrumbsOptions} />
          {!isPreloader ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {!isPreloader ? (
                      <div className="p-2">
                        <Formik
                          initialValues={form}
                          validationSchema={Schema}
                          onSubmit={onSubmit}
                        >
                          {({ values }) => (
                            <>
                              <Form className="form-horizontal">
                                <Row className="mb-3">
                                  <Row className="mb-2">
                                    <CardTitle>預設通知資料</CardTitle>
                                  </Row>
                                </Row>
                                <Row className="mb-3">
                                  <Col lg={12}>
                                    <InputField
                                      name="title"
                                      label="標題"
                                      placeholder="標題"
                                      type="text"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col lg={3}>
                                    <DateField
                                      name="scheduledDate"
                                      label="推送日期時間"
                                      placeholder="推送日期時間"
                                      type="text"
                                    />
                                  </Col>
                                  <Col lg={3} className="p-2">
                                    <InputField
                                      name="scheduledTime"
                                      label=" "
                                      placeholder="推送時間"
                                      type="time"
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Col lg={6}>
                                    <SelectField
                                      name="notificationType"
                                      label="預設通知類型"
                                      placeholder="預設通知類型"
                                      options={[
                                        {
                                          label: '中心活動',
                                          value: 'volunteer',
                                        },
                                        { label: '最新資訊', value: 'news' },
                                        { label: '獎勵', value: 'rewards' },
                                      ]}
                                    />
                                  </Col>
                                  {values.notificationType === 'volunteer' ? (
                                    <Col lg={6}>
                                      <AutoCompleteSelectField
                                        name="volunteerId"
                                        label="所屬中心活動"
                                        placeholder="所屬中心活動"
                                        options={volunteerList}
                                        inputValue={volunteerText}
                                        onInputChange={value =>
                                          setVolunteerText(value)
                                        }
                                        onMenuScrollToBottom={
                                          volunteerScrollToBottom
                                        }
                                        isClearable
                                      />
                                    </Col>
                                  ) : null}
                                  {values.notificationType === 'news' ? (
                                    <Col lg={6}>
                                      <AutoCompleteSelectField
                                        name="newsId"
                                        label="所屬最新資訊"
                                        placeholder="所屬最新資訊"
                                        options={newsList}
                                        inputValue={newsText}
                                        onInputChange={value =>
                                          setNewsText(value)
                                        }
                                        onMenuScrollToBottom={
                                          newsScrollToBottom
                                        }
                                        isClearable
                                      />
                                    </Col>
                                  ) : null}

                                  {values.notificationType === 'rewards' ? (
                                    <Col lg={6}>
                                      <AutoCompleteSelectField
                                        name="rewardId"
                                        label="所屬獎勵"
                                        placeholder="所屬獎勵"
                                        options={rewardList}
                                        inputValue={rewardText}
                                        onInputChange={value => {
                                          setRewardText(value);
                                        }}
                                        onMenuScrollToBottom={
                                          rewardScrollToBottom
                                        }
                                        isClearable
                                      />
                                    </Col>
                                  ) : null}
                                </Row>
                                <div className="mt-3 d-flex flex-row-reverse">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="ms-1"
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <i className="bx bx-loader-circle bx-spin" />
                                    ) : (
                                      '提交'
                                    )}
                                  </Button>
                                </div>
                              </Form>
                            </>
                          )}
                        </Formik>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </>
  );
};
