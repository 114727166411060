import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';
import Select from 'react-select';
import { Label, Input } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  placeholder?: string;
  options: OptionItems[];
  inputValue: string;
  selectOnChange?: (value: any) => void;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onInputChange?: (e) => void;
  disabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  height?: number;
  isInModal?: boolean;
}

const AutoCompleteSelectField = (props: OtherProps & FieldHookConfig<any>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    // inputValue,
    selectOnChange,
    onMenuScrollToBottom,
    onMenuClose,
    onInputChange,
    disabled,
    isClearable,
    menuPlacement,
    height,
    isInModal = false,
  } = props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    // console.log('rendervalue index', index);
    if (index > -1) {
      // console.log('rendervalue options[index]', options[index]);
      return options[index];
    }
    return null;
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: height ? height : 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      {disabled ? (
        <Input
          className="form-control"
          placeholder={placeholder ? placeholder : ''}
          type={'text'}
          autoComplete="new-password"
          style={{ height: 38 }}
          disabled={disabled}
          value={rendervalue() ? rendervalue()!.label : ''}
        />
      ) : (
        <Select
          defaultValue={rendervalue()}
          isMulti={false}
          placeholder={placeholder}
          options={options}
          classNamePrefix="select2-selection"
          {...field}
          value={rendervalue()}
          onChange={e => {
            if (selectOnChange) {
              selectOnChange(e);
            } else {
              if (e) {
                helpers.setValue(e.value);
              } else {
                helpers.setValue(null);
              }
            }
          }}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onMenuClose={onMenuClose}
          captureMenuScroll={true}
          onInputChange={
            onInputChange
              ? e => {
                  if (!(e === '' && options.length >= 1)) {
                    onInputChange(e);
                  }
                }
              : undefined
          }
          // inputValue={inputValue}
          // onInputChange={onInputChange}
          isClearable={isClearable}
          menuPlacement={menuPlacement}
          menuPortalTarget={!isInModal ? document.body : undefined}
          styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999,
            }),
          }}
        />
      )}
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default AutoCompleteSelectField;
