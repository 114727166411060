import { APP_NAME } from 'app/config';
import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';

const TermsPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>私隱條例 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>仁濟醫院社會服務部</h4>
                            <h5>私隱條例</h5>
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <p className="mb-4">
                                仁濟醫院社會服務部(下稱「仁濟」)遵從個人資料(私隱)條例，採用以下政策，以確保手機應用程式使用者之個人資料得到保密。仁濟從手機應用程式收集得來的資料乃源於瀏覽者自願提供的，在未得到手機應用程式使用者同意時，資料僅用作有關慈善籌款及指定用途。
                              </p>

                              <p className="mb-4">
                                仁濟承諾絕不會向外披露任何個人資料，亦不會出售、租借或傳送給予其他機構。
                              </p>

                              <h5 className="mb-3">資料收集及使用</h5>

                              <p className="mb-4">
                                當您瀏覽本手機應用程式時，您毋需提供任何個人資料以瀏覽本手機應用程式但如您登記成為會員、參加、報名參加或登記參加仁濟或其關聯機構舉辦的任何活動或其他宣傳或推廣活動，又或您要求仁濟提供任何宣傳資料、資訊、或服務資料，仁濟可向用戶收集個人資料以作有關用途之用。
                              </p>

                              <h5 className="mb-3">
                                從本手機應用程式用戶收集所得的個人資料，亦可由仁濟作以下用途：
                              </h5>

                              <ol>
                                <li>
                                  發送會員訊息和與您溝通（如您登記成為會員）；
                                </li>
                                <li>
                                  就用戶為此而提供個人資料的有關參與活動、提供服務、兌換服務、或宣傳資訊，而與用戶聯絡；
                                </li>
                                <li>
                                  設計服務內容、活動及其他相關服務資訊，以用於本手機應用程式上登載或提供給用戶；
                                </li>
                                <li>
                                  就用戶的活動及特徵進行統計分析，以便量度對本手機應用程式內容的興趣及使用程度。
                                </li>
                              </ol>

                              <h5 className="mb-3">儲存及取用</h5>
                              <p className="mb-4">
                                所有經由手機應用程式提供予以仁濟的個人資料，在伺服器上取用是受到限制的。只有需要執行合法行動的員工或代理人才可使用。仁濟保留可以透過具機密指引約束的代理人來進行存取的權利。
                              </p>

                              <h5 className="mb-3">網上登記 / 捐款</h5>

                              <p className="mb-4">
                                仁濟採用 Secure Sockets Layer
                                (SSL)的標準，在網上登記中，所有資料以加密方式在互聯網上傳送。
                              </p>

                              <h5 className="mb-3">更新 / 刪除個人資料</h5>
                              <p className="mb-4">
                                如欲更新 /
                                刪除您的個人資料，請以電郵通知手機應用程式管理員
                                <a href="mailto: ito.flcp@ychss.org.hk">
                                  ito.flcp@ychss.org.hk
                                </a>
                                。
                              </p>
                              <p className="mb-4">
                                請定期瀏覽此頁面查閱任何修改及/或修訂。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsPage;
