import {
  Admin,
  AttendRecord,
  ColorOptionItems,
  OptionItems,
  RedeemRecord,
  TimeStamp,
  User,
  Volunteer,
} from '.';

export interface PointRecord extends TimeStamp {
  pointRecordId: number;
  userId: number;
  point: number;
  type: string;
  status: string;
  attendRecordId: number | null;
  receiverUserId: number | null;
  redeemRecordId: number | null;
  AttendRecord?: AttendRecord | null;
  Admin?: Admin | null;
  RedeemRecord?: RedeemRecord | null;
  Volunteer?: Volunteer | null;
  Sender?: User;
  Receiver?: User;
  remarks: string | null;
}

export const PointRecordStatusOption: OptionItems[] = [
  { label: '待確認', value: 'Pending' },
  { label: '成功', value: 'Success' },
  { label: '取消', value: 'Canceled' },
];

export const PointRecordStatusMap = new Map<string, ColorOptionItems>([
  ['Pending', { label: '待確認', value: 'Pending', colorType: 'secondary' }],
  ['Success', { label: '成功', value: 'Success', colorType: 'success' }],
  ['Canceled', { label: '取消', value: 'Canceled', colorType: 'danger' }],
]);

export const PointRecordTypeOption: OptionItems[] = [
  { label: '轉出', value: 'TransferOut' },
  { label: '轉入', value: 'TransferIn' },
  { label: '換領物品', value: 'Redeem' },
  { label: '參加活動', value: 'AttendVolunteer' },
  { label: '管理員新增', value: 'AddByAdmin' },
];

export const PointRecordTypeMap = new Map<string, ColorOptionItems>([
  [
    'TransferOut',
    { label: '轉出', value: 'TransferOut', colorType: 'success' },
  ],
  ['TransferIn', { label: '轉入', value: 'TransferIn', colorType: 'danger' }],
  ['Redeem', { label: '換領物品', value: 'Redeem', colorType: 'warning' }],
  [
    'AttendVolunteer',
    { label: '參加活動', value: 'AttendVolunteer', colorType: 'info' },
  ],
  [
    'AddByAdmin',
    { label: '管理員新增', value: 'AddByAdmin', colorType: 'primary' },
  ],
]);
