import React from 'react';
import { Table } from 'reactstrap';

interface Props {
  columns: any[];
  data: any[];
  keyField: string;
  onClick?: (id: string) => void;
}

function CustomTable({ columns, data, keyField, onClick }: Props) {
  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          {columns.map((column, index) => (
            <th key={`${column.text}_${index}`}>{column.text}</th>
          ))}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                key={`${item[keyField]}_${index}`}
                onClick={() => {
                  if (onClick) {
                    onClick(item[keyField]);
                  }
                }}
              >
                {columns.map((column, subIndex) => {
                  if (column.formatter) {
                    return (
                      <td key={`${item[keyField]}_${index}_${subIndex}`}>
                        {column.formatter(item)}
                      </td>
                    );
                  } else {
                    return (
                      <td key={`${item[keyField]}_${index}_${subIndex}`}>
                        {item[column.dataField]}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td
              colSpan={columns.length}
              className="text-center border-0 text-size-16 fw-bold"
            >
              沒有資料
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );
}

export default CustomTable;
