import {
  Filter,
  ListResult,
  RedeemItemPayload,
  RedeemRecord,
  Reward,
  RewardPolicy,
} from 'app/models';
import Axios from '.';

/**
 * Get reward list (https://flcp-dev.appicidea.com/doc/#/Reward/rewardControllerListReward)
 */

export interface GetRewardItemListParams extends Filter {
  categoryId?: string | number;
  q?: string;
}

export const getRewardItemList = async (params: GetRewardItemListParams) => {
  const { data } = await Axios.get<ListResult<Reward>>('/rewards', { params });
  return data;
};

/**
 * Create new reward https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerCreateNews)
 */

export interface CreateNewRewardItemParams {
  categoryId?: string;
  image?: string;
  title: string;
  quota: number;
  pointPerUnit: number;
  description: string;
}

export const createNewRewardItem = async (
  params: CreateNewRewardItemParams,
) => {
  const { data } = await Axios.post<Reward>('/rewards', params);
  return data;
};

/**
 * Get one reward (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerGetNewsById)
 */

export const getOneRewardItem = async (rewardId: string) => {
  const { data } = await Axios.get<Reward>(`/rewards/${rewardId}`);
  return data;
};

/**
 * Update one reward (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerUpdateNewsById
 */

export const updateOneRewardItem = async (
  rewardId: number,
  params: Partial<CreateNewRewardItemParams>,
) => {
  const { data } = await Axios.patch<string>(`/rewards/${rewardId}`, params);
  return data;
};

/**
 * Delete one reward (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerDeleteNewsById)
 */

export const deleteOneRewardItem = async (rewardId: number) => {
  const { data } = await Axios.delete<string>(`/rewards/${rewardId}`);
  return data;
};

/**
 * List reward redeem record list (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerListRedeemRecord)
 */

export interface GetRewardRedeemRecordListParams extends Filter {
  userId?: string | number;
  rewardId?: string | number;
  status?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export const getRewardRedeemRecordList = async (
  params: GetRewardRedeemRecordListParams,
) => {
  const { data } = await Axios.get<ListResult<RedeemRecord>>(
    '/rewards/redeemRecords',
    { params },
  );
  return data;
};

/**
 * Create one redeem record (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerAddRedeemItemsByAdminRequest)
 */

export interface CreateOneRewardRedeemRecordParams {
  userId: string;
  status: string;
  remarks: string;
  RedeemItems: RedeemItemPayload[];
}

export const createOneRewardRedeemRecord = async (
  params: CreateOneRewardRedeemRecordParams,
) => {
  const { data } = await Axios.post<RedeemRecord>(
    `/rewards/redeemItemsAdmin`,
    params,
  );
  return data;
};

/**
 * Get one reward redeem record (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerGetOneRedeemRecord)
 */

export const getOneRewardRedeemRecord = async (redeemRecordId: string) => {
  const { data } = await Axios.get<RedeemRecord>(
    `/rewards/redeemRecords/${redeemRecordId}`,
  );
  return data;
};

/**
 * Update one reward redeem record (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerUpdateOneRedeemRecord)
 */

export const updateOneRewardRedeemRecord = async (
  redeemRecordId: number,
  params: Partial<CreateOneRewardRedeemRecordParams>,
) => {
  const { data } = await Axios.patch<string>(
    `/rewards/redeemRecords/${redeemRecordId}`,
    params,
  );
  return data;
};

/**
 * Set one redeem record as complete (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerCompletedRedeemRecord)
 */

export const setRewardRedeemRecordAsComplete = async (
  redeemRecordId: string,
) => {
  const { data } = await Axios.post<RedeemRecord>(
    `/rewards/redeemRecords/${redeemRecordId}/complete`,
  );
  return data;
};

/**
 * Set one redeem record as cancel (https://flcp-dev.appicidea.com/doc/#/Reward/rewardsControllerCancelRedeemRecord)
 */

export const setRewardRedeemRecordAsCancel = async (redeemRecordId: string) => {
  const { data } = await Axios.post<RedeemRecord>(
    `/rewards/redeemRecords/${redeemRecordId}/cancel`,
  );
  return data;
};

/**
 * get reward policy (https://flcp-dev.appicidea.com/doc/#/RewardPolicy/rewardPolicyControllerGetOneRewardPolicy)
 */

export const getLatestRewardPolicy = async () => {
  const { data } = await Axios.get<RewardPolicy>('/rewardPolicy/latest');
  return data;
};

/**
 * update reward policy (https://flcp-dev.appicidea.com/doc/#/RewardPolicy/rewardPolicyControllerUpdateLatestRewardPolicy)
 */

export const updateRewardPolicy = async (content: string) => {
  const { data } = await Axios.put<any>('/rewardPolicy/latest', { content });
  return data;
};
