import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
  component: any;
  isAuthProtected: boolean;
  layout?: any;
  exact?: boolean;
  path: string;
  requiredRole?: string[];
}

const ProtectedRoute = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  exact,
  path,
  requiredRole,
  ...rest
}: Props) => {
  const currentRole = JSON.parse(localStorage.getItem('authUser') as string)
    ?.role;
  const userHasRequiredRole: boolean = requiredRole
    ? currentRole
      ? requiredRole.includes(currentRole)
      : true
    : true;

  return (
    <Route
      {...rest}
      path={path}
      exact
      render={props => {
        if (isAuthProtected && !localStorage.getItem('authUser')) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        } else if (userHasRequiredRole) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        } else {
          return <Redirect to="/notFound" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
