export interface ListResult<T> {
  count: number;
  rows: T[];
}

export interface UserResult<T> {
  count: number;
  row: T[];
}

export interface Column {
  name: string;
  field: string;
  isImage?: boolean;
  isSecureImage?: boolean;
  isAvatar?: boolean;
  dateFormat?: string;
  buildingNumber?: boolean;
}

export interface Filter {
  limit?: number;
  offset: number;
}

export interface TimeStamp {
  createdAt: string;
  deletedAt: null | string;
  updatedAt: string;
}

export interface OptionItems {
  value: string | number;
  label: string;
}

export interface ColorOptionItems {
  value: string | number;
  label: string;
  colorType:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark';
}

export interface GroupedOptionItems {
  key: OptionItems;
}

export interface BreadcrumbItemProps {
  title: string;
  path: string;
}

export interface LocalFile {
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  path?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
  delete?: boolean;
  preview?: null | undefined | any | string;
  formattedSize?: string;
}

export interface FileResponse {
  fileName: string;
  url: string;
  supportSizes: string[];
}

export interface CheckBoxOption {
  value: string;
  label: string;
  check: boolean;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly OptionItems[];
}

export const REGION = [
  '中西區',
  '東區',
  '南區',
  '灣仔區',
  '九龍城區',
  '觀塘區',
  '深水埗區',
  '黃大仙區',
  '油尖旺區',
  '離島區',
  '葵青區',
  '北區',
  '西貢區',
  '沙田區',
  '大埔區',
  '荃灣區',
  '屯門區',
  '元朗區',
];
