import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';

import { Label, Input } from 'reactstrap';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  hidden?: boolean;
  rows?: number;
  height?: number;
  min?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const InputField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helper] = useField(props);
  const {
    label,
    type,
    placeholder,
    hidden,
    rows,
    min,
    height,
    onChange,
    disabled,
  } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
        hidden && 'visually-hidden',
      )}
      style={type !== 'textarea' ? { height: height ? height : 85 } : {}}
    >
      {label ? <Label>{props.label}</Label> : null}
      {onChange ? (
        <Input
          className="form-control"
          {...field}
          placeholder={placeholder}
          type={type}
          autoComplete="new-password"
          rows={rows}
          min={min}
          onChange={e => {
            onChange(e);
            helper.setValue(e.target.value);
          }}
          disabled={disabled}
        />
      ) : (
        <Input
          className="form-control"
          {...field}
          placeholder={placeholder}
          type={type}
          autoComplete="new-password"
          rows={rows}
          min={min}
          disabled={disabled}
        />
      )}

      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default InputField;
