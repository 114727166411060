import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  // DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';

//i18n
// import { useTranslation } from 'react-i18next';

// users
import user1 from 'app/assets/images/users/avatar.png';
import { ADMIN_ROLES } from 'app/models';

const ProfileMenu = props => {
  const currentRole = JSON.parse(localStorage.getItem('authUser') as string)
    ?.role;
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState('Admin');
  // const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser') as string);
      setusername(obj.displayName);
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <Badge
            pill
            className={`badge-soft-${
              ADMIN_ROLES.get(currentRole)?.color
            } ms-1 p-2 font-size-12 rounded badge-pill`}
          >
            {ADMIN_ROLES.get(currentRole)?.label}
          </Badge>

          <span className="d-none d-lg-inline-block ms-2 me-1">
            {username}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
