/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useCallback } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AdminRoleEnum } from 'app/models';

const SidebarContent = props => {
  const ref = useRef<any>();
  const location = useLocation();
  const currentRole = JSON.parse(localStorage.getItem('authUser') as string)
    ?.role;

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          const pathStr = pathName.split('/');
          let containPath = false;
          pathStr.forEach(str => {
            if (str && `/${str}` === items[i].pathname) {
              containPath = true;
            }
          });
          if (containPath) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>
            <li>
              <NavLink
                to="/banners"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-layout"></i>
                <span>橫幅廣告</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/volunteers">
                <i className="bx bx-calendar-event"></i>
                <span>中心活動</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/volunteers/events"
                    activeClassName="active mm-active"
                  >
                    中心活動
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/volunteers/attendRecords"
                    activeClassName="active mm-active"
                  >
                    報名記錄
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/rewards">
                <i className="bx bx-gift"></i>
                <span>獎勵</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/rewards/items"
                    activeClassName="active mm-active"
                  >
                    獎勵物品
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rewards/categories"
                    activeClassName="active mm-active"
                  >
                    物品類別
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rewards/redeemRecords"
                    activeClassName="active mm-active"
                  >
                    換領記錄
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rewards/terms"
                    activeClassName="active mm-active"
                  >
                    換領條款
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/news"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-news"></i>
                <span>最新資訊</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/centres"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-building"></i>
                <span>服務中心</span>
              </NavLink>
            </li>
            {currentRole === AdminRoleEnum.SuperAdmin ? (
              <li>
                <NavLink
                  to="/transferPoints"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-transfer"></i>
                  <span>積分轉移</span>
                </NavLink>
              </li>
            ) : null}
            <li>
              <NavLink
                to="/trackings"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-receipt"></i>
                <span>會員追踪</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/notifications"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-timer"></i>
                <span>預設通知</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/sorting">
                <i className="bx bx-sort"></i>
                <span>類別排序</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/sorting/newsCategories"
                    activeClassName="active mm-active"
                  >
                    資訊類別
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/sorting/centreCategories"
                    activeClassName="active mm-active"
                  >
                    中心類別
                  </NavLink>
                </li>
              </ul>
            </li>
            {currentRole === AdminRoleEnum.SuperAdmin ? (
              <li>
                <NavLink
                  to="/take-down"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="mdi mdi-archive-arrow-down"></i>
                  <span>移除申請</span>
                </NavLink>
              </li>
            ) : null}

            <li>
              <NavLink
                to="/users"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user"></i>
                <span>會員</span>
              </NavLink>
            </li>
            {currentRole === AdminRoleEnum.SuperAdmin ? (
              <li>
                <NavLink
                  to="/admins"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-user-circle"></i>
                  <span>管理員</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
