import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { BannerListPage, BannerFormPage } from 'app/pages/BannerPage';
import { TransferPointFormPage } from 'app/pages/TransferPointPage';
import { UserListPage, UserFormPage, UserDetailPage } from 'app/pages/UserPage';
import { NewsListPage, NewsFormPage } from 'app/pages/NewsPage';
import { CentreListPage, CentreFormPage } from 'app/pages/CentresPage';
import {
  AttendRecordListPage,
  AttendRecordFormPage,
} from 'app/pages/AttendRecordPage';
import {
  VolunteerListPage,
  VolunteerDetailPage,
  VolunteerFormPage,
} from 'app/pages/VolunteerPage';
import { CategoryListPage, CategoryFormPage } from 'app/pages/CategoryPage';
import {
  RedeemRecordListPage,
  RedeemRecordFormPage,
  RedeemRecordDetailPage,
} from 'app/pages/RedeemRecordPage';
import {
  RewardItemListPage,
  RewardItemFormPage,
  RewardItemDetailPage,
  RewardTermsPage,
} from 'app/pages/RewardItemPage';

// import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { TrackingListPage } from 'app/pages/TrackingPage/TrackingListPage';
import { NotificationFormPage } from 'app/pages/NotificationPage/NotificationFormPage';
import { NotificationListPage } from 'app/pages/NotificationPage';
import {
  NewsCategorySortListPage,
  CentreCategorySortListPage,
} from 'app/pages/SortListPage';
import { AdminRoleEnum } from 'app/models';
import TermsPage from 'app/pages/Terms';
import { TakeDownPage } from 'app/pages/TakeDownFormPage';
import { TakeDownDetailPage, TakeDownListPage } from 'app/pages/TakeDownPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
  requiredRole?: string[];
}
const userRoutes: Route[] = [
  // admin
  {
    path: '/admins',
    component: AdminListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin],
  },
  {
    path: '/admins/new',
    component: AdminFormPage,
    exact: true,
    requiredRole: [AdminRoleEnum.SuperAdmin],
  },
  {
    path: '/admins/edit/:adminId',
    component: AdminFormPage,
    exact: true,
    requiredRole: [AdminRoleEnum.SuperAdmin],
  },
  {
    path: '/admins/edit/:adminId/:password',
    component: AdminFormPage,
    exact: true,
    requiredRole: [AdminRoleEnum.SuperAdmin],
  },
  {
    path: '/banners',
    component: BannerListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/banners/new',
    component: BannerFormPage,
    exact: true,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/banners/edit/:bannerId',
    component: BannerFormPage,
    exact: true,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/items/',
    component: RewardItemListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/items/new',
    component: RewardItemFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/items/edit/:rewardId',
    component: RewardItemFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/items/detail/:rewardId',
    component: RewardItemDetailPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/categories/',
    component: CategoryListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/categories/new',
    component: CategoryFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/categories/edit/:categoryId',
    component: CategoryFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/redeemRecords/',
    component: RedeemRecordListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/redeemRecords/new',
    component: RedeemRecordFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/redeemRecords/edit/:redeemRecordId',
    component: RedeemRecordFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/redeemRecords/detail/:redeemRecordId',
    component: RedeemRecordDetailPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/rewards/terms',
    component: RewardTermsPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/events',
    component: VolunteerListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/events/new',
    component: VolunteerFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/events/edit/:volunteerId',
    component: VolunteerFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/events/detail/:volunteerId',
    component: VolunteerDetailPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/attendRecords',
    component: AttendRecordListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/attendRecords/new',
    component: AttendRecordFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/volunteers/attendRecords/edit/:attendRecordId',
    component: AttendRecordFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/trackings',
    component: TrackingListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/notifications',
    component: NotificationListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/notifications/new',
    component: NotificationFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/news',
    component: NewsListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/news/new',
    component: NewsFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/news/edit/:newsId',
    component: NewsFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/centres',
    component: CentreListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/centres/new',
    component: CentreFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/centres/edit/:centreId',
    component: CentreFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/sorting/newsCategories',
    component: NewsCategorySortListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/sorting/centreCategories',
    component: CentreCategorySortListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/users',
    component: UserListPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/users/new/',
    component: UserFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/users/edit/:userId',
    component: UserFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/users/detail/:userId',
    component: UserDetailPage,
    requiredRole: [AdminRoleEnum.SuperAdmin, AdminRoleEnum.Admin],
  },
  {
    path: '/transferPoints/',
    component: TransferPointFormPage,
    requiredRole: [AdminRoleEnum.SuperAdmin],
  },
  {
    path: '/take-down',
    component: TakeDownListPage,
  },
  {
    path: '/take-down/detail/:takedownId',
    component: TakeDownDetailPage,
  },
  { path: '/', component: () => <Redirect to="/volunteers/events" /> },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/takedown', component: TakeDownPage },
  { path: '/terms', component: TermsPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
