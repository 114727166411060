import Axios from '.';
import { AdminLoginPayload, ListResult, Admin, Filter } from '../models';

/**
 *  Admin Login (https://flcp-dev.appicidea.com/doc/#/Auth/authControllerAdminLogin)
 */
export const adminLogin = async (params: AdminLoginPayload) => {
  const { data } = await Axios.post<any>('/auth/admin', params);
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export interface GetAdminListParams extends Filter {}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admins', {
    params,
  });
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export interface CreateNewAdminParams {
  loginName?: string;
  role?: string;
  displayName: string;
  password?: string;
}
export const createNewAdmin = async (params: CreateNewAdminParams) => {
  const { data } = await Axios.post<Admin>('/admins', params);
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export const getOneAdmin = async (adminId: string) => {
  const { data } = await Axios.get<Admin>(`/admins/${adminId}`);
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export const updateOneAdmin = async (
  adminId: number,
  params: { displayName: string },
) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, params);
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export const deleteOneAdmin = async (adminId: number) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export const getProfile = async () => {
  const { data } = await Axios.get<Admin>('/me/admin/profile');
  return data;
};

/**
 *  Get admin List (https://flcp-dev.appicidea.com/doc/#/Admin/adminControllerListAdmins)
 */
export const resetAdminPassword = async (
  adminId: number,
  params: { password?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admins/${adminId}/resetPassword`,
    params,
  );
  return data;
};

/**
 *  Admin logout (https://flcp-dev.appicidea.com/doc/#/Auth/authControllerLogout)
 */
export const logout = async () => {
  const { data } = await Axios.post('/auth/logout');
  return data;
};
