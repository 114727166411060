export const API_URL =
  process.env.REACT_APP_API_URL || 'https://flcp-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PDF_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const SUBPAGE_PAGE_SIZE = 10;
export const TOAST_TIME = 5000;
export const APP_NAME = 'FLCP';
export const IMAGE_SIZE = 1024 * 10000;
export const PDF_SIZE = 1024 * 100000;
