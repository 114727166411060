import { Filter, ListResult } from 'app/models';
import { News, NewsCategory } from 'app/models';
import Axios from '.';

/**
 * Get News list (https://flcp-dev.appicidea.com/doc/#/News/newsControllerListNews)
 */

export interface GetNewsListParams extends Filter {
  category?: string;
  title?: string;
  approvalStatus?: string;
}

export const getNewsList = async (params: GetNewsListParams) => {
  const { data } = await Axios.get<ListResult<News>>('/news', { params });
  return data;
};

/**
 * Create new News (https://flcp-dev.appicidea.com/doc/#/News/newsControllerCreateNews)
 */

export interface CreateNewNewsParams {
  categoryName: string;
  title: string;
  content?: string;
  bannerImage?: string;
  pdf?: string | null;
  volunteerId?: string;
}

export const createNewNews = async (params: CreateNewNewsParams) => {
  const { data } = await Axios.post<News>('/news', params);
  return data;
};

/**
 * Get one News (https://flcp-dev.appicidea.com/doc/#/News/newsControllerGetNewsById)
 */

export const getOneNews = async (newsId: string) => {
  const { data } = await Axios.get<News>(`/news/${newsId}`);
  return data;
};

/**
 * Update one News (https://flcp-dev.appicidea.com/doc/#/News/newsControllerUpdateNewsById)
 */

export const updateOneNews = async (
  newsId: number,
  params: CreateNewNewsParams,
) => {
  const { data } = await Axios.patch<string>(`/news/${newsId}`, params);
  return data;
};

/**
 * Delete one News (https://flcp-dev.appicidea.com/doc/#/News/newsControllerDeleteNewsById)
 */

export const deleteOneNews = async (newsId: number) => {
  const { data } = await Axios.delete<string>(`/news/${newsId}`);
  return data;
};

/**
 * Get News Category list (https://flcp-dev.appicidea.com/doc/#/Centres/useFulMaterialControllerListCategory)
 */

export const getNewsCategoryList = async () => {
  const { data } = await Axios.get<NewsCategory[]>('/news/category');
  return data;
};

/**
 * Update News Category list (https://flcp-dev.appicidea.com/doc/#/News/newsControllerUpdateCategoryOrder)
 */

export interface UpdateNewCategoryListParams {
  categoryName: string;
  ordering: number;
}

export const updateNewsCategoryList = async (
  params: UpdateNewCategoryListParams[],
) => {
  const { data } = await Axios.put<NewsCategory[]>(
    '/news/category/ordering',
    params,
  );
  return data;
};

/**
 * Send News Notification (https://flcp-dev.appicidea.com/doc/#/News/newsControllerSendNotification)
 */

export interface SendNewsNotificationParams {
  title: string;
}
export const sendNewsNotification = async (
  newsId: number,
  params: SendNewsNotificationParams,
) => {
  const { data } = await Axios.post<string>(
    `/news/${newsId}/notification`,
    params,
  );
  return data;
};

/**
 *  Approve one news, By Admin (https://flcp-dev.appicidea.com/doc/#/News/newsControllerApproveNewsById)
 */
export const approveOneNews = async (newsId: number) => {
  const { data } = await Axios.post<any>(`/news/${newsId}/approve/`);
  return data;
};
