import Breadcrumbs from 'app/components/Common/Breadcrumb';
import { DatePickerFilter } from 'app/components/Filter';
import { API_URL, APP_NAME } from 'app/config';

import { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

export const TrackingListPage = () => {
  const location = useLocation();
  const [url, setURL] = useState<string>(
    `${API_URL}/v1/rewards/redeemRecords/report`,
  );
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const createdAtFrom = params.get('createdAtFrom');
    const createdAtTo = params.get('createdAtTo');
    setURL(
      `${API_URL}/v1/tracking/export?${
        createdAtFrom ? `createdAtFrom=${createdAtFrom}&` : ''
      }${createdAtTo ? `createdAtTo=${createdAtTo}&` : ''}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>會員行動記錄 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="會員行動記錄" breadcrumbItem="會員行動記錄" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col lg={8}>
                        <DatePickerFilter
                          label=""
                          name="createdAtFrom"
                          name2="createdAtTo"
                          placeholder="匯出行動記錄日期範圍"
                          mode="range"
                          clearable
                        />
                      </Col>
                      <Col
                        lg={4}
                        className="d-flex flex-row-reverse align-items-end"
                      >
                        <div>
                          <a
                            href={url}
                            className="btn btn-info mb-2"
                            role="button"
                            aria-pressed="true"
                          >
                            {` ${'匯出行動記錄'}`}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
